/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';

import LoadingScreen from './components/common/LoadingScreen';
// eslint-disable-next-line import/extensions
import Router from './routes';
import { errorHandler, loadProgressBar } from './utils/axios';
import { addScrollbarStyle } from './utils/scrollbarStyle';
import { setOldToken } from './redux/slices/auth';
import { initAllNotiServices } from './firebase';

// eslint-disable-next-line react/prop-types
const App = ({ isReady }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const { isLoggedIn } = useSelector((state) => state.auth);
  // ----------------------------------------------------------------------
  useEffect(() => {
    errorHandler(enqueueSnackbar, closeSnackbar);
    return () => {};
  }, [enqueueSnackbar, closeSnackbar]);

  // ----------------------------------------------------------------------
  useEffect(() => {
    // loadProgressBar();
    setOldToken();
    addScrollbarStyle(theme);
    if (isLoggedIn) {
      initAllNotiServices();
    }
    return () => {};
  }, [theme, isLoggedIn]);

  return (
    <>
      {isReady ? (
        <Router />
      ) : (
        <LoadingScreen
          mainLoading
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 9999,
            position: 'fixed',
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isReady: state.auth.isReady,
});

export default connect(mapStateToProps)(App);
