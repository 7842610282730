import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { groupBy } from '../../utils/stringFormat';
import { dispatch } from '../store';
const alertNoti = JSON.parse(localStorage.getItem('alertNoti'));
const initialState = {
  list: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  alertNoti: alertNoti,
};

export const notiSlice = createSlice({
  name: 'noti',
  initialState,
  reducers: {
    clearNotiList: (state) => {
      localStorage.removeItem('notiList');
      state.list = [];
    },
    setAlertNoti: (state, action) => {
      localStorage.setItem('alertNoti', action.payload);
      state.alertNoti = action.payload;
    },

    clearList: (state) => {
      state.list = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        results: [],
      };
    },
    setIsGettingList: (state, action) => {
      state.isGettingList = action.payload;
    },
    setList: (state, action) => {
      var data = action.payload;
      const results = [...state.list.results, ...data.results];
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: results,
      };
      state.list = newState;
    },
  },
});

export const {
  clearNotiList,
  setAlertNoti,

  clearList,
  setIsGettingList,
  setList,
} = notiSlice.actions;

export default notiSlice.reducer;

export const handleSetAlertNoti = (param) => {
  dispatch(setAlertNoti(param));
};

export const handleGettingNotiList = async (next) => {
  if (next) {
    dispatch(setIsGettingList(true));

    try {
      const url = `user/notifications/?page=${next}&page_size=10`;
      const res = await axios.get(url);
      if (res.status === 200) {
        if (parseInt(next) == 1) {
          dispatch(clearList());
        }
        const data = res.data;
        dispatch(setList(data));
        dispatch(setIsGettingList(false));
      }
      return Promise.resolve();
    } catch (err) {
      dispatch(setIsGettingList(false));
      return Promise.reject(err);
    }
  }
};

export const handleGroupNotiArray = (arr) => {
  let dataResults = groupBy(arr, 'created_at').reverse();
  return dataResults;
};
