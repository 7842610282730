import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { isValidToken, setAxiosToken } from '../../utils/jwt';
import { dispatch } from '../store';
import { handleGettingNotiList, handleSetAlertNoti } from './noti';
import { handleGettingPlayersList } from './player';
import {
  handleGettingAdminPaymentMethods,
  handleGettingAgentPaymentMethods,
  handleGettingCheckoutPendingList,
  handleGettingRequestPendingList,
} from './wallet';
const initialState = {
  isLoggedIn: false,
  user: null,
  isReady: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signinSuccess: (state) => {
      state.isLoggedIn = true;
      state.isReady = false;
    },
    getUserSuccess: (state, action) => {
      state.user = action.payload;
      state.isReady = true;
    },
    signOutSuccess: (state) => {
      state.isLoggedIn = false;
      state.accessToken = null;
      state.refreshToken = null;
    },
    setIsReady: (state) => {
      state.isReady = true;
    },
  },
});

export const { signinSuccess, getUserSuccess, signOutSuccess, setIsReady } = authSlice.actions;

export default authSlice.reducer;

// actions
export const handleSignOut = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('FCMToken');
  setAxiosToken();
  dispatch(signOutSuccess());
};

export const setOldToken = () => {
  const TokenString = localStorage.getItem('token');
  if (TokenString) {
    const token = JSON.parse(TokenString);
    if (isValidToken(token.access)) {
      setAxiosToken(token.access);
      dispatch(signinSuccess());
      getAllData();
    } else {
      // something to do
    }
  } else {
    dispatch(setIsReady());
  }
};

export const handleSignInSuccess = (token) => {
  if (token.access) {
    localStorage.setItem('token', JSON.stringify(token));
  }
  setAxiosToken(token.access);
  dispatch(signinSuccess());
  getAllData();
};

// User
export const getUser = () => {
  const url = `user/agent/`;
  axios.get(url).then(({ data }) => {
    dispatch(getUserSuccess(data));
    handleGettingRequestPendingList(1);
    handleGettingCheckoutPendingList(1);
  });
};

const getAllData = async () => {
  getUser();
  handleGettingNotiList(1);
  handleGettingAdminPaymentMethods();
  handleGettingAgentPaymentMethods();
  handleGettingPlayersList();
};
