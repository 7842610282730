/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// pages
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

function AuthGuard({ children }) {
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const { isLoggedIn } = useSelector((state) => state.auth);

  if (!isLoggedIn) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={'/auth/login'} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}

export default AuthGuard;
