import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from 'axios';
import { groupBy } from '../../utils/stringFormat';

const initialState = {
  transactionList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingTransactionList: false,
};

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    clearTransactionList: (state) => {
      state.transactionList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingTransactionList: (state, action) => {
      state.isGettingTransactionList = action.payload;
    },
    setTransactionList: (state, action) => {
      var data = action.payload;
      const results = [...state.transactionList.results, ...data.results];
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: results,
      };
      state.transactionList = newState;
    },
  },
});

export const { clearTransactionList, setIsGettingTransactionList, setTransactionList } = transactionSlice.actions;

export default transactionSlice.reducer;

export const handleGettingTransactionList = async (next, filter) => {
  if (next) {
    dispatch(setIsGettingTransactionList(true));

    try {
      let url = '';
      if (filter.startDate && filter.toDate) {
        url = `wallet/transition-records/?page=${next}&page_size=100&type=${filter.type}&payment_type=${filter.paymentType}&start_date=${filter.startDate}&to_date=${filter.toDate}`;
      } else {
        url = `wallet/transition-records/?page=${next}&page_size=100&type=${filter.type}&payment_type=${filter.paymentType}`;
      }

      const res = await axios.get(url);

      if (parseInt(next) == 1) {
        dispatch(clearTransactionList());
      }

      if (res.status === 200) {
        const data = res.data;
        dispatch(setTransactionList(data));
        dispatch(setIsGettingTransactionList(false));
      }
      return Promise.resolve();
    } catch (err) {
      dispatch(setIsGettingTransactionList(false));
      return Promise.reject(err);
    }
  }
};

export const handleGroupTransArray = (arr) => {
  const grouped = groupBy(arr, 'date').reverse();
  return grouped;
};
