import { createSlice } from '@reduxjs/toolkit';
import { dispatch, store } from '../store';
import axios from 'axios';
import { groupBy } from '../../utils/stringFormat';

const initialState = {
  agentPaymentMethods: [],
  isGettingAgentPaymentMethods: false,

  adminPaymentMethods: [],
  isGettingAdminPaymentMethods: false,

  requestPendingList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingRequestPendingList: false,
  requestHistoryList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingRequestHistoryList: false,

  checkoutPendingList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingCheckoutPendingList: false,
  checkoutHistoryList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingCheckoutHistoryList: false,

  commissionList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingCommissionList: false,
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    clearRequestPendingList: (state) => {
      state.requestPendingList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingRequestPendingList: (state, action) => {
      state.isGettingRequestPendingList = action.payload;
    },
    setRequestPendingList: (state, action) => {
      var data = action.payload;
      const results = [...state.requestPendingList.results, ...data.results];
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: results,
      };
      state.requestPendingList = newState;
    },

    clearRequestHistoryList: (state) => {
      state.requestHistoryList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingRequestHistoryList: (state, action) => {
      state.isGettingRequestHistoryList = action.payload;
    },
    setRequestHistoryList: (state, action) => {
      var data = action.payload;
      const results = [...state.requestHistoryList.results, ...data.results];
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: results,
      };
      state.requestHistoryList = newState;
    },

    clearCheckoutPendingList: (state) => {
      state.checkoutPendingList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingCheckoutPendingList: (state, action) => {
      state.isGettingCheckoutPendingList = action.payload;
    },
    setCheckoutPendingList: (state, action) => {
      var data = action.payload;
      const results = [...state.checkoutPendingList.results, ...data.results];
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: results,
      };
      state.checkoutPendingList = newState;
    },

    clearCheckoutHistoryList: (state) => {
      state.checkoutHistoryList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingCheckoutHistoryList: (state, action) => {
      state.isGettingCheckoutHistoryList = action.payload;
    },
    setCheckoutHistoryList: (state, action) => {
      var data = action.payload;
      const results = [...state.checkoutHistoryList.results, ...data.results];
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: results,
      };
      state.checkoutHistoryList = newState;
    },

    clearCommissionList: (state) => {
      state.commissionList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingCommissionList: (state, action) => {
      state.isGettingCommissionList = action.payload;
    },
    setCommissionList: (state, action) => {
      var data = action.payload;
      const results = [...state.commissionList.results, ...data.results];
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: results,
      };
      state.commissionList = newState;
    },

    setIsGettingAdminPaymentMethods: (state, action) => {
      state.isGettingAdminPaymentMethods = action.payload;
    },
    setAdminPaymentMethods: (state, action) => {
      state.adminPaymentMethods = action.payload;
    },

    setIsGettingAgentPaymentMethods: (state, action) => {
      state.isGettingAgentPaymentMethods = action.payload;
    },
    setAgentPaymentMethods: (state, action) => {
      state.agentPaymentMethods = action.payload;
    },
  },
});

export const {
  clearRequestPendingList,
  setIsGettingRequestPendingList,
  setRequestPendingList,
  clearRequestHistoryList,
  setIsGettingRequestHistoryList,
  setRequestHistoryList,

  clearCheckoutPendingList,
  setIsGettingCheckoutPendingList,
  setCheckoutPendingList,
  clearCheckoutHistoryList,
  setIsGettingCheckoutHistoryList,
  setCheckoutHistoryList,

  clearCommissionList,
  setIsGettingCommissionList,
  setCommissionList,

  setAdminPaymentMethods,
  setIsGettingAdminPaymentMethods,

  setAgentPaymentMethods,
  setIsGettingAgentPaymentMethods,
} = walletSlice.actions;

export default walletSlice.reducer;

export const handleGettingRequestPendingList = async (next) => {
  if (next) {
    dispatch(setIsGettingRequestPendingList(true));
    const state = store.getState();
    var aid = state.auth.user.agent_info.id;
    try {
      const url = `wallet/player/transition-list/?page=${next}&page_size=1000&aid=${aid}&status=Pending`;
      const res = await axios.get(url);
      if (res.status === 200) {
        const data = res.data;
        if (next == 1) {
          dispatch(clearRequestPendingList());
        }
        dispatch(setRequestPendingList(data));
        dispatch(setIsGettingRequestPendingList(false));
      }
      return Promise.resolve();
    } catch (err) {
      dispatch(setIsGettingRequestPendingList(false));
      return Promise.reject(err);
    }
  }
};

export const handleGettingRequestHistoryList = async (next) => {
  if (next) {
    dispatch(setIsGettingRequestHistoryList(true));
    const state = store.getState();
    var aid = state.auth.user.agent_info.id;
    try {
      const url = `wallet/player/transition-list/?page=${next}&page_size=30&aid=${aid}&status=-Pending`;
      const res = await axios.get(url);

      if (res.status === 200) {
        const data = res.data;
        if (parseInt(next) == 1) {
          dispatch(clearRequestHistoryList());
        }
        dispatch(setRequestHistoryList(data));
        dispatch(setIsGettingRequestHistoryList(false));
      }
      return Promise.resolve();
    } catch (err) {
      dispatch(setIsGettingRequestHistoryList(false));
      return Promise.reject(err);
    }
  }
};

export const handleGettingCheckoutPendingList = async (next) => {
  if (next) {
    dispatch(setIsGettingCheckoutPendingList(true));

    try {
      const url = `wallet/agent/my-transition-list/?page=${next}&page_size=1000&status=Pending&type=`;
      const res = await axios.get(url);
      if (res.status === 200) {
        if (parseInt(next) == 1) {
          dispatch(clearCheckoutPendingList());
        }
        const data = res.data;
        dispatch(setCheckoutPendingList(data));
        dispatch(setIsGettingCheckoutPendingList(false));
      }
      return Promise.resolve();
    } catch (err) {
      dispatch(setIsGettingCheckoutPendingList(false));
      return Promise.reject(err);
    }
  }
};

export const handleGettingCheckoutHistoryList = async (next, filter) => {
  if (next) {
    const { type, startDate, toDate } = filter;
    dispatch(setIsGettingCheckoutHistoryList(true));

    try {
      let url = '';
      if (startDate && toDate) {
        url = `wallet/agent/my-transition-list/?page=${next}&page_size=30&status=-Pending&type=${type}&start_date=${filter.startDate}&to_date=${filter.toDate}`;
      } else {
        url = `wallet/agent/my-transition-list/?page=${next}&page_size=30&status=-Pending&type=${type}`;
      }
      const res = await axios.get(url);
      if (parseInt(next) == 1) {
        dispatch(clearCheckoutHistoryList());
      }

      if (res.status === 200) {
        const data = res.data;
        dispatch(setCheckoutHistoryList(data));
        dispatch(setIsGettingCheckoutHistoryList(false));
      }
      return Promise.resolve();
    } catch (err) {
      dispatch(setIsGettingCheckoutHistoryList(false));
      return Promise.reject(err);
    }
  }
};

export const handleGroupCheckoutArray = (arr) => {
  const grouped = groupBy(arr, 'created_at').reverse();
  return grouped;
};

export const handleGettingCommissionList = async (next, type) => {
  if (next) {
    dispatch(setIsGettingCommissionList(true));
    try {
      const url = `wallet/agent/my-commission/?page=${next}&page_size=1000&type=${type}`;
      const res = await axios.get(url);
      if (res.status === 200) {
        if (parseInt(next) == 1) {
          dispatch(clearCommissionList());
        }
        const data = res.data;
        dispatch(setCommissionList(data));
        dispatch(setIsGettingCommissionList(false));
      }
      return Promise.resolve();
    } catch (err) {
      dispatch(setIsGettingCommissionList(false));
      return Promise.reject(err);
    }
  }
};

export const handleGroupCommissionArray = (arr) => {
  let dataResults = [];
  let grouped = groupBy(arr, 'date').reverse();
  grouped.forEach((item) => {
    dataResults.push(groupBy(item, 'time'));
  });

  return dataResults;
};

export const handleGettingAdminPaymentMethods = async () => {
  dispatch(setIsGettingAdminPaymentMethods(true));
  try {
    let url = `wallet/admin-payment-methods/`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const data = res.data;
      dispatch(setAdminPaymentMethods(data));
      dispatch(setIsGettingAdminPaymentMethods(false));
    }
  } catch (err) {
    dispatch(setIsGettingAdminPaymentMethods(false));
  }
};

export const handleGettingAgentPaymentMethods = async () => {
  dispatch(setIsGettingAgentPaymentMethods(true));
  try {
    let url = `wallet/agent/payment-methods/`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const data = res.data;
      dispatch(setAgentPaymentMethods(data));
      dispatch(setIsGettingAgentPaymentMethods(false));
    }
  } catch (err) {
    dispatch(setIsGettingAgentPaymentMethods(false));
  }
};
